import { applyMode, Mode } from '@amzn/awsui-global-styles';

import { SET_LOGGED_IN_USER, SET_LOGGED_IN_USER_DETAILS, ADD_USER_VIEW, REMOVE_USER_VIEW, SET_DARK_MODE, SET_PAGE_TITLE, SET_METRIC_RANGE, CLEAR_USER_VIEW, SET_METRIC_VIEW } from "../actionTypes";

const initialState = {
  currentUsers: [],
  darkMode: false,
  loggedInUser: null,
  loggedInUserDetails: {},
  pageTitle: 'STAR Dashboard',
  metricRange: { label: "Quarterly", value: "Quarterly" },
  activeMetricsTab: 'coverity'
};

export default function setView(state = initialState, action) {
  switch (action.type) {
    case SET_LOGGED_IN_USER: {
      return {
        ...state,
        loggedInUser: action.payload
      }
    }
    case SET_LOGGED_IN_USER_DETAILS: {
      return {
        ...state,
        loggedInUserDetails: action.payload
      }
    }
    case ADD_USER_VIEW: {
      if(action.payload != null && action.payload.id != null) {
        let userExists = false;
        for(const user of state.currentUsers) {
          if(user.id === action.payload.id) {
            userExists = true;
            break;
          }
        }
        if(!userExists) {
          return {
            ...state,
            currentUsers: [...state.currentUsers, action.payload]
          }
        }
      }
      
      return state;
    }
    case REMOVE_USER_VIEW: {
      if(action.payload != null && action.payload.id != null) {
        let userExists = false;
        for(const user of state.currentUsers) {
          if(user.id === action.payload.id) {
            userExists = true;
            break;
          }
        }

        if (userExists) {
          return {
            ...state,
            currentUsers: state.currentUsers.filter(item => action.payload.id !== item.id)
          }
        }
      }
      return state;
    }
    case CLEAR_USER_VIEW: {
      return {
        ...state,
        currentUsers: []
      }
    }
    case SET_DARK_MODE: {
      applyMode(action.payload ? Mode.Dark : Mode.Light); 
      return {
        ...state,
        darkMode: action.payload
      }
    }
    case SET_METRIC_RANGE: {
      return {
        ...state,
        metricRange: action.payload
      }
    }
    case SET_METRIC_VIEW: {
      return {
        ...state,
        activeMetricsTab: action.payload
      }
    }
    case SET_PAGE_TITLE: {
      return {
        ...state,
        pageTitle: action.payload
      }
    }
    default:
      return state;
  }
}

export const ADD_USER_VIEW = "ADD_USER_VIEW";
export const REMOVE_USER_VIEW = "REMOVE_USER_VIEW";
export const CLEAR_USER_VIEW = "CLEAR_USER_VIEW";
export const SET_LOGGED_IN_USER = "SET_LOGGED_IN_USER";
export const SET_LOGGED_IN_USER_DETAILS = "SET_LOGGED_IN_USER_DETAILS";
export const SET_DARK_MODE = "SET_DARK_MODE";
export const SET_PAGE_TITLE = "SET_PAGE_TITLE";
export const SET_METRIC_RANGE = "SET_METRIC_RANGE";
export const SET_METRIC_VIEW = "SET_METRIC_VIEW";
export const LOG_IN = "USER_LOG_IN";
export const LOG_IN_COMPLETED = "USER_LOG_IN_COMPLETED";
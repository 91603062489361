import React from 'react';
import { connect } from 'react-redux'
import { removeUserView, addUserView } from '../../app/actions'
import UserCards from "./UserCards";

class UserHierarchy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
	        loadingState: 'finished'
        };
    }
  
    render() {
        return (
            <UserCards 
                users={this.props.currentUsers} 
                actionMode={'remove'} 
                format={'short'}
                setSelectedUser={this.props.addUserView} 
                removeSelectedUser={this.props.removeUserView}
                emptyHeader={"No Users Added"}
                permissions={this.props.loggedInUserDetails.permissions ? this.props.loggedInUserDetails.permissions : ''}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUsers: state.currentViews.currentUsers,
        currentMetricRange: state.currentViews.metricRange,
        loggedInUserDetails: state.currentViews.loggedInUserDetails
    };
};

export default connect(mapStateToProps, { removeUserView, addUserView })(UserHierarchy)
import React from 'react';
import { connect } from 'react-redux'
import { Alert, TextContent, SpaceBetween } from '@amzn/awsui-components-react';
import MetricRangeSelector from "./MetricRangeSelector";
import AverageSLAGraph from "./AverageSLAGraph";
import JiraStateGraph from "./JiraCountGraph";
import DeviceCoverageGraph from "./DeviceCoverageGraph";

function MetricGraphs(props) {
    return(
        <SpaceBetween direction="vertical" size="xl">
            <AverageSLAGraph metricName={'oss'} metricRange={props.metricRange.value} loadingState={props.loadingState} metricData={props.metricData} />
            <hr/>
            <DeviceCoverageGraph metricName={'oss'} metricRange={props.metricRange.value} loadingState={props.loadingState} metricData={props.metricData} />
            <hr/>
            <JiraStateGraph metricName={'oss'} metricRange={props.metricRange.value} loadingState={props.loadingState} metricData={props.metricData} />
        </SpaceBetween>
    );
}

export class OSSMetricsView extends React.Component {
    
    render() {
        if(this.props.currentUsers.length < 1) {
            return (
                <Alert
                    visible={true}
                    type="info"
                    header="No selected users"
                >
                    Select one or more users to view OSS metrics.
                </Alert>
            );
        }
        else if(this.props.currentUsers.length === 1 && (this.props.currentUsers[0] && this.props.currentUsers[0]['source'] && this.props.currentUsers[0]['source'] === 'PAPI')) {
            return (
                <Alert
                    visible={true}
                    type="info"
                    header="No metrics available"
                >
                    {(this.props.currentUsers[0]['person_details'] && this.props.currentUsers[0]['person_details']['name']) ? this.props.currentUsers[0]['person_details']['name'] : 'The selected user'} does not have any OSS metrics.
                </Alert>
            );
        }

        return (
            <div>
                <SpaceBetween direction="vertical" size="xxl">
                    <SpaceBetween direction="horizontal" size="xl">
                        <TextContent><h2>OSS Metrics</h2></TextContent>
                        <MetricRangeSelector />
                    </SpaceBetween>
                    <hr/>
                    <MetricGraphs metricRange={this.props.metricRange} metricData={this.props.currentUsers} />
                </SpaceBetween>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUsers: state.currentViews.currentUsers,
        metricRange: state.currentViews.metricRange
    };
};

export default connect(mapStateToProps, null)(OSSMetricsView)
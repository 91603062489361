import * as React from "react";
import LineChart from "@amzn/awsui-components-react/polaris/line-chart";
import { Box, Button, Cards, SpaceBetween, Table, Header } from '@amzn/awsui-components-react';
import { quarterCompare } from "./QuarterDefinitions";
import TrendIndicator from "./TrendIndicator";
import UserCardHeader from "../User/UserCardHeader"

const DeviceCoverageGraph = (props) => {
    if(props.metricRange === 'Quarterly') { //Quarterly metrics
      let tableColumnNames = [{
        id: 'user',
        header: 'User',
        cell: e => e.name,
        sortingField: 'User'
      }];
      let sortableTableColumns = [
        { id: 'user', field: 'user' }
      ];
      let tableRows = [];

      let seriesData = [];
      let xRange = [];
      let yMax = 5;

      for(const user of props.metricData) {
          let series = {
              title: user.person_details.name + " (" + user.id + ")",
              type: 'line',
              data: [],
              valueFormatter: function o(e) {
                  return e.toFixed(0);
                }
          }

          let tableRow = {
            name: user.person_details.name + " (" + user.id + ")",
            csv_values: []
          }

          if(user.historical_metric_values) {
            user.historical_metric_values.sort(quarterCompare);
            for(const quarter of user.historical_metric_values) {
              if(quarter[props.metricName]) {
                if('device_coverage' in quarter[props.metricName]) {
                  if(!xRange.includes(quarter.id)) {
                    xRange.push(quarter.id);
                    tableColumnNames.push({
                      id: quarter.id,
                      header: quarter.id,
                      cell: e => e[quarter.id],
                      sortingField: quarter.id
                    })
                    sortableTableColumns.push({ id: quarter.id, field: quarter.id })
                  }
                  if(quarter[props.metricName].device_coverage.length > yMax) {
                      yMax = quarter[props.metricName].device_coverage.length;
                  }
  
                  series.data.push({
                      x: quarter.id,
                      y: quarter[props.metricName].device_coverage.length
                  })
                  tableRow[quarter.id] = quarter[props.metricName].device_coverage.length;
                  tableRow['csv_values'].push(quarter[props.metricName].device_coverage.length);
                }
              }
            }
          }
          
          if(series.data.length > 0){
            tableRows.push(tableRow);
            seriesData.push(series);
          }
      }

      if(seriesData.length > 0) {
          yMax = yMax * 1.25;
      }

      return (
        <SpaceBetween direction="vertical" size="l">
          <LineChart
            series={seriesData}
            xDomain={xRange}
            yDomain={[0, yMax]}
            i18nStrings={{
              filterLabel: "Filter displayed data",
              filterPlaceholder: "Filter data",
              filterSelectedAriaLabel: "selected",
              legendAriaLabel: "Legend",
              chartAriaRoleDescription: "line chart"
            }}
            ariaLabel="Device Coverage Chart"
            errorText="Error loading data."
            height={300}
            hideFilter
            loadingText="Loading chart"
            legendTitle="User"
            recoveryText="Retry"
            statusType={props.loadingState}
            xScaleType="categorical"
            xTitle="Quarter"
            yTitle="Device Coverage (Number of unique devices)"
            empty={
              <Box textAlign="center" color="inherit">
                <b>No Data</b>
                <Box variant="p" color="inherit">
                  No device coverage data available
                </Box>
              </Box>
            }
            noMatch={
              <Box textAlign="center" color="inherit">
                <b>No matching data</b>
                <Box variant="p" color="inherit">
                  There is no matching data to display
                </Box>
                <Button>Clear filter</Button>
              </Box>
            }
          />
          <Table
              columnDefinitions={tableColumnNames}
              items={tableRows}
              loadingText="Loading device coverage data"
              resizableColumns
              empty={
                <Box textAlign="center" color="inherit">
                  <b>No Data</b>
                  <Box
                    padding={{ bottom: "s" }}
                    variant="p"
                    color="inherit"
                  >
                    No device coverage data to display.
                  </Box>
                </Box>
              }
              header={
                <Header>
                  <SpaceBetween direction="horizontal" size="xl">
                    Device Coverage Data Table
                    <Button variant="icon" iconName="download" onClick={() => {
                      let csvData = tableColumnNames.map(e => e.header) + "\n";
                      csvData += tableRows.map(e => e.name + "," + e.csv_values.join(",")).join("\n");
                      let csvContent = "data:text/csv;charset=utf-8," + csvData;
                      var encodedUri = encodeURI(csvContent);
                      var link = document.createElement("a");
                      link.setAttribute("href", encodedUri);
                      link.setAttribute("download", "coverity_device_coverage.csv");
                      document.body.appendChild(link); 
                      link.click();
                    }}/> 
                  </SpaceBetween> 
                </Header>
              }
            />
          </SpaceBetween>
      );
    }
    else { //Current live metrics
      const cardDefinition = {
          header: item => (
            <UserCardHeader user={item.user}/>
          ),
          sections: [
            {
              id: "current_device_coverage_metric",
              header: "Device Coverage",
              content: item => (
                item.devices.length > 0 ? (item.devices.length === 1 ? item.devices.length + " Device" : item.devices.length + " Devices") : '--'
              )
            },
            {
              id: "current_device_coverage_trend",
              header: "Trend",
              content: item => (
                <TrendIndicator trend={item.trend} desiredTrend={'up'} pctChange={item.pctChange} lastQuarter={item.lastQuarter} lastValue={item.lastValue}/>
              )
            },
            {
              id: "current_device_coverage_list",
              header: "Device List",
              content: item => (
                item.devices.length > 0 ? <small>{item.devices.join(', ')}</small> : '--'
              )
            },
          ]
        };

      let seriesData = []
      for(const user of props.metricData) {
        if(user.current_metric_values && user.current_metric_values[props.metricName]) {
            if(user.historical_metric_values) {
              user.historical_metric_values.sort(quarterCompare);
            }
            
            let lastQuarter = user.historical_metric_values && user.historical_metric_values.length > 0 ? user.historical_metric_values[user.historical_metric_values.length-1] : null;
            let data = {
              user: user,
              devices: user.current_metric_values[props.metricName].device_coverage ? user.current_metric_values[props.metricName].device_coverage : [],
              pctChange: 0,
              trend: 'none'
            };

            if(lastQuarter) {
              let lastQuarterDeviceCoverage = lastQuarter[props.metricName].device_coverage ? lastQuarter[props.metricName].device_coverage.length : 0;
              data['lastQuarter'] = lastQuarter.id;
              data['lastValue'] = lastQuarterDeviceCoverage === 1 ? lastQuarterDeviceCoverage + " Device" : lastQuarterDeviceCoverage + " Devices";
              if(lastQuarterDeviceCoverage < 1) {
                data['pctChange'] = data['devices'].length * 100;
              }
              else {
                data['pctChange'] = Math.abs((data['devices'].length - lastQuarterDeviceCoverage) / lastQuarterDeviceCoverage * 100);
              }
    
              if(data['devices'].length > lastQuarter[props.metricName].device_coverage.length) {
                data['trend'] = 'up';
              }
              else if(data['devices'].length < lastQuarter[props.metricName].device_coverage.length) {
                data['trend'] = 'down';
              }
              else {
                data['trend'] = 'same';
              }
            }
            seriesData.push(data);
        }
      }

      return (
        <div>
          <h3>Device Coverage</h3>
          <Cards
            cardDefinition={cardDefinition}
            items={seriesData ? seriesData : []}
            empty={
              <div className="awsui-util-t-c">
                <div className="awsui-util-pt-s awsui-util-mb-xs">
                  <b>No Data</b>
                </div>
                <p className="awsui-util-mb-s">
                  No device coverage data available
                </p>
              </div>	
            }
            loading={props.loadingState === 'loading' ? true: false}
            loadingText={'Loading metric'}
          />	
        </div>
      );
    }
  }

export default DeviceCoverageGraph;
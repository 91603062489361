import * as React from "react";
import LineChart from "@amzn/awsui-components-react/polaris/line-chart";
import { Box, Button, Cards, SpaceBetween, Table, Header } from '@amzn/awsui-components-react';
import { quarterCompare } from "./QuarterDefinitions";
import TrendIndicator from "./TrendIndicator";
import UserCardHeader from "../User/UserCardHeader"

const JiraStateGraph = (props) => {
    if(props.metricRange === 'Quarterly') { //Quarterly metrics
      let tableColumnNames = [{
        id: 'user',
        header: 'User',
        cell: e => e.name,
        sortingField: 'User'
      }];
      let sortableTableColumns = [
        { id: 'user', field: 'user' }
      ];
      let tableRows = [];

      let seriesData = [];
      let xRange = [];
      let yMax = 8;

      for(const user of props.metricData) {
          let series = {
              title: user.person_details.name + " (" + user.id + ")",
              type: 'bar',
              data: [],
              valueFormatter: function o(e) {
                  return e.toFixed(0);
                }
          }
          
          let tableRow = {
            name: user.person_details.name + " (" + user.id + ")",
            csv_values: []
          }

          if(user.historical_metric_values) {
            user.historical_metric_values.sort(quarterCompare);
            for(const quarter of user.historical_metric_values) {
              if(quarter[props.metricName]) {
                let totalCount = 0;
                // eslint-disable-next-line
                for (const [key, value] of Object.entries(quarter[props.metricName].status)) {
                    totalCount += value;
                }
                
                if(!xRange.includes(quarter.id)) {
                    xRange.push(quarter.id);
                    tableColumnNames.push({
                      id: quarter.id,
                      header: quarter.id,
                      cell: e => e[quarter.id],
                      sortingField: quarter.id
                    })
                    sortableTableColumns.push({ id: quarter.id, field: quarter.id })
                }
                if(totalCount > yMax) {
                    yMax = totalCount;
                }
  
                series.data.push({
                    x: quarter.id,
                    y: totalCount
                })
                tableRow[quarter.id] = totalCount;
                tableRow['csv_values'].push(totalCount);
              }
            }
          }
          
          if(series.data.length > 0){
            tableRows.push(tableRow);
            seriesData.push(series);
          }
      }

      if(seriesData.length > 0) {
        yMax = yMax * 1.25;
      }

      return (
        <SpaceBetween direction="vertical" size="l">
          <LineChart
            series={seriesData}
            xDomain={xRange}
            yDomain={[0, yMax]}
            i18nStrings={{
              filterLabel: "Filter displayed data",
              filterPlaceholder: "Filter data",
              filterSelectedAriaLabel: "selected",
              legendAriaLabel: "Legend",
              chartAriaRoleDescription: "line chart"
            }}
            ariaLabel="Filed Jiras Chart"
            errorText="Error loading data."
            height={300}
            hideFilter
            loadingText="Loading chart"
            legendTitle="User"
            recoveryText="Retry"
            statusType={props.loadingState}
            xScaleType="categorical"
            xTitle="Quarter"
            yTitle="Jiras Filed"
            empty={
              <Box textAlign="center" color="inherit">
                <b>No Data</b>
                <Box variant="p" color="inherit">
                  No Jira filing data available
                </Box>
              </Box>
            }
            noMatch={
              <Box textAlign="center" color="inherit">
                <b>No matching data</b>
                <Box variant="p" color="inherit">
                  There is no matching data to display
                </Box>
                <Button>Clear filter</Button>
              </Box>
            }
          />
          <Table
              columnDefinitions={tableColumnNames}
              items={tableRows}
              loadingText="Loading Jira count data"
              resizableColumns
              empty={
                <Box textAlign="center" color="inherit">
                  <b>No Data</b>
                  <Box
                    padding={{ bottom: "s" }}
                    variant="p"
                    color="inherit"
                  >
                    No Jira count data to display.
                  </Box>
                </Box>
              }
              header={
                <Header>
                  <SpaceBetween direction="horizontal" size="xl">
                    Jira Count Data Table
                    <Button variant="icon" iconName="download" onClick={() => {
                      let csvData = tableColumnNames.map(e => e.header) + "\n";
                      csvData += tableRows.map(e => e.name + "," + e.csv_values.join(",")).join("\n");
                      let csvContent = "data:text/csv;charset=utf-8," + csvData;
                      var encodedUri = encodeURI(csvContent);
                      var link = document.createElement("a");
                      link.setAttribute("href", encodedUri);
                      link.setAttribute("download", "coverity_jira_count.csv");
                      document.body.appendChild(link); 
                      link.click();
                    }}/> 
                  </SpaceBetween> 
                </Header>
              }
            />
        </SpaceBetween>
      );
    }
    else { //Current live metrics
      const cardDefinition = {
          header: item => (
            <UserCardHeader user={item.user}/>
          ),
          sections: [
            {
              id: "current_jiras_filed",
              header: "Filed",
              content: item => (
                item.jirasFiled === 1 ? item.jirasFiled + " Jira" : item.jirasFiled + " Jiras"
              )
            },
            {
              id: "current_jiras_fixed",
              header: "Fixed or Closed",
              content: item => (
                item.jirasFixed === 1 ? item.jirasFixed + " Jira" : item.jirasFixed + " Jiras"
              )
            },
            {
              id: "current_jira_fix_rate",
              header: "Fix Rate",
              content: item => (
                item.jirasFiled > 0 ? item.fixRate.toFixed(0) + "%" : '--'
              )
            },
            {
              id: "current_jiras_filed_trend",
              header: "Jiras Filed Trend",
              content: item => (
                <TrendIndicator trend={item.jirasFiledTrend} desiredTrend={'down'} pctChange={item.jirasFiledPctChange} lastQuarter={item.lastQuarter} lastValue={item.jirasFiledTrendLastValue}/>
              )
            },
            {
              id: "current_jiras_fix_rate_trend",
              header: "Fix Rate Trend",
              content: item => (
                <TrendIndicator trend={item.fixRateTrend} desiredTrend={'up'} pctChange={item.fixRatePctChange} lastQuarter={item.lastQuarter} lastValue={item.fixRateTrendLastValue}/>
              )
            },
          ]
        };

      let seriesData = []
      for(const user of props.metricData) {
        if(user.current_metric_values && user.current_metric_values[props.metricName]) {
            if(user.historical_metric_values) {
              user.historical_metric_values.sort(quarterCompare);
            }
            let lastQuarter = user.historical_metric_values && user.historical_metric_values.length > 0 ? user.historical_metric_values[user.historical_metric_values.length-1] : null;
            let data = {
              user: user,
              jirasFiled: 0,
              jirasFixed: 0,
              fixRate: 0,
              jirasFiledTrend: 'none',
              jirasFiledPctChange: 0,
              fixRateTrend: 'none',
              fixRatePctChange: 0
            };

            //Current quarter calculations
            // eslint-disable-next-line
            for (const [key, value] of Object.entries(user.current_metric_values[props.metricName].status)) {
                data['jirasFiled'] += value;
                if(key === 'Closed' || key === 'Resolved') {
                  data['jirasFixed'] += value;
                }
            }
            if(data['jirasFixed'] > 0) {
              data['fixRate'] = (data['jirasFixed'] / data['jirasFiled']) * 100
            }

            if(lastQuarter) {
              data['lastQuarter'] = lastQuarter.id;
              //Last quarter calculations for comparison
              // eslint-disable-next-line
              let lastQuarterJirasFiled = 0;
              let lastQuarterJirasFixed = 0;
              let lastQuarterFixRate = 0;
              for (const [key, value] of Object.entries(lastQuarter[props.metricName].status)) {
                  lastQuarterJirasFiled += value;
                  if(key === 'Closed' || key === 'Resolved') {
                    lastQuarterJirasFixed += value;
                  }
              }
              if(lastQuarterJirasFixed > 0) {
                lastQuarterFixRate = (lastQuarterJirasFixed / lastQuarterJirasFiled) * 100
              }
              
              //Jiras filed trend
              data['jirasFiledTrendLastValue'] = lastQuarterJirasFiled === 1 ? lastQuarterJirasFiled + " Jira" : lastQuarterJirasFiled + " Jiras";

              if(lastQuarterJirasFiled < 1) {
                data['jirasFiledPctChange'] = data['jirasFiled'] * 100;
              }
              else {
                data['jirasFiledPctChange'] = (data['jirasFiled'] - lastQuarterJirasFiled) / lastQuarterJirasFiled * 100;
              }
              if(data['jirasFiled'] > lastQuarterJirasFiled) {
                data['jirasFiledTrend'] = 'up';
              }
              else if(data['jirasFiled'] < lastQuarterJirasFiled) {
                data['jirasFiledTrend'] = 'down';
              }
              else {
                data['jirasFiledTrend'] = 'same';
              }

              //Fix rate trend
              data['fixRateTrendLastValue'] = lastQuarterFixRate.toFixed(0) + "%";

              if(lastQuarterFixRate < 1) {
                data['fixRatePctChange'] = data['fixRate'] * 100;
              }
              else {
                data['fixRatePctChange'] = Math.abs((data['fixRate'] - lastQuarterFixRate) / lastQuarterFixRate * 100);
              }
              if(data['fixRate'] > lastQuarterFixRate) {
                data['fixRateTrend'] = 'up';
              }
              else if(data['fixRate'] < lastQuarterFixRate) {
                data['fixRateTrend'] = 'down';
              }
              else {
                data['fixRateTrend'] = 'same';
              }
            }
            seriesData.push(data);
        }
      }

      return (
        <div>
          <h3>Jira Filing Stats</h3>
          <Cards
            cardDefinition={cardDefinition}
            items={seriesData ? seriesData : []}
            empty={
              <div className="awsui-util-t-c">
                <div className="awsui-util-pt-s awsui-util-mb-xs">
                  <b>No Data</b>
                </div>
                <p className="awsui-util-mb-s">
                  No Jira filing data available
                </p>
              </div>	
            }
            loading={props.loadingState === 'loading' ? true: false}
            loadingText={'Loading metric'}
          />	
        </div>
      );
    }
  }

export default JiraStateGraph;
import * as React from "react";
import { SpaceBetween, Popover } from '@amzn/awsui-components-react';

export default function TrendIndicator(props) {
    const GREEN = "#2e7d32";
    const RED = "#ff0000"
    const TREND_UP = "\u25B2";
    const TREND_DOWN = "\u25BC";

    let trendColor = props.desiredTrend === props.trend ? GREEN : RED;
    let trendIcon = props.trend === 'down' ? TREND_DOWN : TREND_UP;

    if(props.trend === 'none') {
        return(
            '--'
        );
    }

    if(props.pctChange.toFixed(0) < 1 || props.trend === 'same') {
        return(
            'No Change'
        );
    }

    return(
        <SpaceBetween direction="horizontal" size="xxs">
            <font color={trendColor}>{trendIcon}</font>
            <b><font color={trendColor}>{props.pctChange.toFixed(0) + "%"}</font></b>
            <Popover
                dismissButton={false}
                position="top"
                size="medium"
                triggerType="text"
                content={props.lastQuarter + ": " + props.lastValue}
            >
                {" since " + props.lastQuarter}
            </Popover>
        </SpaceBetween>
    );
}
import { CognitoAuth } from "amazon-cognito-auth-js";
import { getAuthConfig } from "./Config";
import { setLoggedInUser } from "../../app/actions";
import { getCurEnv } from "./envVarsReader";

let JWTToken;

//User authentication and authorization
export const auth = async (dispatch) => {
    let decodedAuthObj;
    if(getCurEnv() === 'local') {
        decodedAuthObj = {
            identities: [
                {
                    userId: "user"
                }
            ]
        }
        JWTToken = "local"
    }
    else {
        let authObj;
        try {
            authObj = await generateOrGetAuthObj();
        } catch (e) {
            window.location.href = "/unauth";
            return;
        }
        decodedAuthObj = authObj.decodePayload();
        JWTToken = authObj.getJwtToken();
    }

    const identities = decodedAuthObj.identities;
    if (!identities && identities.length !== 1) {
        return;
    }
    dispatch(setLoggedInUser(identities[0].userId));
}

export const getJwtToken = () => {
    return JWTToken;
}

function removeQueryFromLocation() {
    // Replace the href because the Cognito passes the OAuth2 grant code in the query string and the grant code is not reusable
    if (window.history.length > 0) {
        const newHref = window.location.href.split('?code')[0];
        window.history.replaceState(undefined, 'STAR Dashboard', newHref);
    }
}

const generateOrGetAuthObj = () => {
    return new Promise((resolve, reject) => {
        const authConfig = getAuthConfig();
        const auth = new CognitoAuth(authConfig)

        auth.userhandler = {
            onFailure: (err) => {
                removeQueryFromLocation();
                reject(err);
            },
            onSuccess: (result) => {
                removeQueryFromLocation();
                resolve(auth.getSignInUserSession().getIdToken());
            }
        };

        auth.useCodeGrantFlow();

        const href = window.location.href;
        const session = auth.getSignInUserSession();

        if (session.isValid()) {
            resolve(session.getIdToken());
        } else if (href.indexOf('?code') > 0) {
            auth.parseCognitoWebResponse(href);     // triggers userhandler
        } else {
            auth.clearCachedTokensScopes();
            auth.getSession();
        }
    });
}
import axios from "axios";
import React from 'react';
import { connect } from 'react-redux'
import { addUserView } from '../../app/actions'
import { Input, Modal, Box, SpaceBetween, Button } from '@amzn/awsui-components-react';
import { getJwtToken } from '../Common/Auth';
import { getApiURL } from "../Common/envVarsReader";
import UserCards from "./UserCards";

export class UserSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
	        loadingState: 'pending',
            searchResults: [],
            userQuery: '',
            userQueryInvalid: false,
            modalVisible: false,
            selectedUser: {}
        };
        this.fetchUserHints = this.fetchUserHints.bind(this);
        this.setSelectedUser = this.setSelectedUser.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
    }
  
    async fetchUserHints(filteringText) {
        const options = {
            headers: {
                'Authorization': `Bearer ${getJwtToken()}`,
                'Content-Type': 'application/json'
            },
        }
        
        this.setState({loadingState: 'loading'})
        let searchResults = [];
        try {
            let response = await axios.get(`${getApiURL()}/userSearch?term=` + filteringText, options);
            for(const user of response['data']) {
                searchResults.push({
                    id: user.id,
                    person_details: {
                        name: user.person_details.name,
                        title: user.person_details.title,
                        job_level: user.person_details.job_level,
                        tenure_days: user.person_details.tenure_days,
                        cost_center_name: user.person_details.cost_center_name
                    }
                });
            }
            this.setState({searchResults: searchResults, loadingState: 'finished'})
        } catch (e) {
            console.log(e);
            this.setState({loadingState: 'error'});
        }
    }

    async setSelectedUser(selectedUser) {
        this.setState({selectedUser: selectedUser})
        this.props.addUserView(selectedUser);
        this.handleModalClose();
    }

    handleKeyUp(key) {
        if(key === 'Enter') {
            if(this.state.userQuery.length < 3) {
                this.setState({userQueryInvalid: true});
            }
            else {
                this.setState({modalVisible: true, userQueryInvalid: false});
                this.fetchUserHints(this.state.userQuery);
            }
        }
    }

    handleModalClose() {
        this.setState({modalVisible: false, userQuery: ''})
    }
  
    render() {
        return (
            <div>
                <Input
                    onChange={({ detail }) => this.setState({userQuery: detail.value})}
                    onKeyUp={({ detail }) => this.handleKeyUp(detail.key)}
                    value={this.state.userQuery}
                    type="search"
                    placeholder="Search by name or alias"
                    invalid={this.state.userQueryInvalid}
                />
                <br/>
                <Modal
                    onDismiss={() => this.setState({modalVisible: false})}
                    visible={this.state.modalVisible}
                    closeAriaLabel="Close modal"
                    size="medium"
                    footer={
                        <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => this.handleModalClose()} variant="primary">Close</Button>
                        </SpaceBetween>
                        </Box>
                    }
                    header={"Search Results for '" + this.state.userQuery + "'"}
                >
                    <UserCards 
                        state={this.state.loadingState} 
                        users={this.state.searchResults} 
                        actionMode={'select'} 
                        format={'long'}
                        setSelectedUser={this.setSelectedUser} 
                        removeSelectedUser={null} 
                        emptyHeader={"No Users Found"}
                        emptyMessage={"Your search returned no users."}
                    />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUsers: state.currentViews.currentUsers
    };
};

export default connect(mapStateToProps, { addUserView })(UserSearch)
import { ADD_USER_VIEW, REMOVE_USER_VIEW, CLEAR_USER_VIEW, SET_DARK_MODE, SET_LOGGED_IN_USER, SET_METRIC_RANGE, SET_LOGGED_IN_USER_DETAILS } from "./actionTypes";

export const addUserView = id => ({
    type: ADD_USER_VIEW,
    payload: id
});

export const removeUserView = id => ({
    type: REMOVE_USER_VIEW,
    payload: id
});

export const clearUserView = () => ({
    type: CLEAR_USER_VIEW
});

export const setLoggedInUser = id => ({
    type: SET_LOGGED_IN_USER,
    payload: id
});

export const setDarkMode = enabled => ({
    type: SET_DARK_MODE,
    payload: enabled
});

export const setMetricRange = range => ({
    type: SET_METRIC_RANGE,
    payload: range
});

export const setLoggedInUserDetails = details => ({
    type: SET_LOGGED_IN_USER_DETAILS,
    payload: details
});
import axios from "axios";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { AppLayout, Tabs } from '@amzn/awsui-components-react';
import { getApiURL } from "./components/Common/envVarsReader";
import { auth, getJwtToken } from './components/Common/Auth';
import { Navigation } from './components/Common/Navigation';
import { ADD_USER_VIEW, SET_METRIC_RANGE, SET_METRIC_VIEW } from "./app/actionTypes";
import CoverityMetricsView from './components/Metrics/CoverityMetricsView';
import OSSMetricsView from './components/Metrics/OSSMetricsView';
import PuffLoader from "react-spinners/PuffLoader";
import '@amzn/awsui-global-styles/polaris.css';
import './styles/center.scss';

export default function App() {
  const dispatch = useDispatch();
  auth(dispatch)

  const userAlias = useSelector((state) => state.currentViews.loggedInUser);
  const activeMetricsTab = useSelector((state) => state.currentViews.activeMetricsTab);
  const [navigationOpen, setNavigationOpen] = React.useState(true);

  const addQueryStringUserViews = async (users) => {
    for(const user_id of users) {   
      let user = await loadUserData(user_id);

      if(user) {
        dispatch({
          type: ADD_USER_VIEW,
          payload: user
        });
      }
    }
  }

  const loadUserData = async(user_id) => {
    const options = {
        headers: {
            'Authorization': `Bearer ${getJwtToken()}`,
            'Content-Type': 'application/json'
        },
    }

    try {
        let response = await axios.get(`${getApiURL()}/user?id=` + user_id, options);
        let user = response['data'];
        return user;
    } catch (e) {
        console.log(e);
    }
    
    return null;
  }

  useEffect(() => {
    //Handle saved parameters in query string
    let search = window.location.search;
    let params = new URLSearchParams(search);
    if(params.get('userView')) {
        let users = params.get('userView').split(',');
        addQueryStringUserViews(users);
    }
    if(params.get('metricRange')) {
      dispatch({
        type: SET_METRIC_RANGE,
        payload: {
          label: params.get('metricRange'),
          value: params.get('metricRange')
        }
      })
    }
    if(params.get('metricView')) {
      dispatch({
        type: SET_METRIC_VIEW,
        payload: params.get('metricView')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAlias]);

  const Content = () => {
    return (
        <div>
          <Tabs
            tabs={[
                {
                    label: "Coverity",
                    id: "coverity",
                    content: <CoverityMetricsView/>
                },
                {
                    label: "OSS",
                    id: "oss",
                    content: <OSSMetricsView/>,
                }
            ]}
            variant="container"
            activeTabId={activeMetricsTab}
            onChange={({ detail }) => dispatch({
                type: SET_METRIC_VIEW,
                payload: detail.activeTabId
              }
            )}
          />
        </div>
    );
  }

  return userAlias ?
    (
      <AppLayout
            disableContentPaddings={true}
            content={<Content />}
            navigation={<Navigation />}
            navigationOpen={navigationOpen}
            onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
            toolsHide={true}
        />
    )
    :
    (
      <div class="centered">
        <PuffLoader color={"#eeb348"} loading={true} size={150} />
      </div>
    )
}
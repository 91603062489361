import React from 'react';
import { connect } from 'react-redux'
import { setMetricRange } from '../../app/actions'
import { Select } from '@amzn/awsui-components-react';

export class MetricRangeSelector extends React.Component {
    setSelectedRange(range) {
        this.props.setMetricRange(range);
    }

    render() {
        return (
            <Select
                selectedOption={this.props.metricRange}
                onChange={({ detail }) => this.setSelectedRange(detail.selectedOption)}
                options={[
                    { label: "Quarterly", value: "Quarterly" },
                    { label: "Current (Live)", value: "Current" }
                ]}
                selectedAriaLabel="Selected"
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        metricRange: state.currentViews.metricRange
    };
};

export default connect(mapStateToProps, { setMetricRange })(MetricRangeSelector)
const config = {
    AppWebDomain: 'digital-security-automation-star-beta.auth.us-west-2.amazoncognito.com',
    ClientId: '2tmeq8i9vns8kajfiob2u1vpm2',
    RedirectUriSignIn: window.location.origin,
    RedirectUriSignOut: window.location.origin,
    TokenScopesArray: ['openid'],
    UserPoolId: 'us-west-2_SNc0Fh6Bb'
}

export function getAuthConfig() {
    return config;
}
import React from 'react';
import copy from 'copy-to-clipboard';
import { Popover, Button, StatusIndicator } from '@amzn/awsui-components-react';

export default function ClipboardCopy(props) {
    if(props.disabled) {
        return '';
    }

    return (
        <Popover
            size="small"
            position="right"
            triggerType="custom"
            dismissButton={false}
            content={<StatusIndicator type="success">{props.successMessage}</StatusIndicator>}
        >
            <Button
                variant="normal"
                onClick={() => {
                    copy(props.copyText)
                }}
            >
                Share
            </Button>
        </Popover>
    );
}
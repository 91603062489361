import * as React from "react";
import LineChart from "@amzn/awsui-components-react/polaris/line-chart";
import { Box, Button, Cards, SpaceBetween, Table, Header } from '@amzn/awsui-components-react';
import { quarterCompare } from "./QuarterDefinitions";
import TrendIndicator from "./TrendIndicator";
import UserCardHeader from "../User/UserCardHeader"

const SLA_DAYS = 110;

const AverageSLAGraph = (props) => {
    if(props.metricRange === 'Quarterly') { //Quarterly metrics
      let tableColumnNames = [{
        id: 'user',
        header: 'User',
        cell: e => e.name,
        sortingField: 'User'
      }];
      let sortableTableColumns = [
        { id: 'user', field: 'user' }
      ];
      let tableRows = [];

      let seriesData = [];
      let xRange = [];
      let yMax = SLA_DAYS;

      for(const user of props.metricData) {
          let series = {
              title: user.person_details.name + " (" + user.id + ")",
              type: 'line',
              data: [],
              valueFormatter: function o(e) {
                  return e.toFixed(0);
                }
          }

          let tableRow = {
            name: user.person_details.name + " (" + user.id + ")",
            csv_values: []
          }

          if(user.historical_metric_values) {
            user.historical_metric_values.sort(quarterCompare);
            for(const quarter of user.historical_metric_values) {
              if(quarter[props.metricName]) {
                if(!xRange.includes(quarter.id)) {
                    xRange.push(quarter.id);
                    tableColumnNames.push({
                        id: quarter.id,
                        header: quarter.id,
                        cell: e => e[quarter.id],
                        sortingField: quarter.id
                    })
                    sortableTableColumns.push({ id: quarter.id, field: quarter.id })
                }
                if(quarter[props.metricName].avg_sla > yMax) {
                    yMax = quarter[props.metricName].avg_sla;
                }
  
                series.data.push({
                    x: quarter.id,
                    y: quarter[props.metricName].avg_sla
                })
                tableRow[quarter.id] = quarter[props.metricName].avg_sla.toFixed(0);
                tableRow['csv_values'].push(quarter[props.metricName].avg_sla.toFixed(0));
              }
            }
          }
          
          if(series.data.length > 0){
            tableRows.push(tableRow);
            seriesData.push(series);
          }
      }

      if(seriesData.length > 0) {
          yMax = yMax * 1.25;
          seriesData.push({
            title: "D-Team SLA",
            type: "threshold",
            y: SLA_DAYS,
            color: "#000000"
          });
      }

      return (
        <SpaceBetween direction="vertical" size="l">
          <LineChart
            series={seriesData}
            xDomain={xRange}
            yDomain={[0, yMax]}
            i18nStrings={{
              filterLabel: "Filter displayed data",
              filterPlaceholder: "Filter data",
              filterSelectedAriaLabel: "selected",
              legendAriaLabel: "Legend",
              chartAriaRoleDescription: "line chart"
            }}
            ariaLabel="Quarterly Average Jira SLA Chart"
            errorText="Error loading data."
            height={300}
            hideFilter
            loadingText="Loading chart"
            legendTitle="User"
            recoveryText="Retry"
            statusType={props.loadingState}
            xScaleType="categorical"
            xTitle="Quarter"
            yTitle="Average Jira SLA (Days)"
            empty={
              <Box textAlign="center" color="inherit">
                <b>No Data</b>
                <Box variant="p" color="inherit">
                  No Jira SLA data available
                </Box>
              </Box>
            }
            noMatch={
              <Box textAlign="center" color="inherit">
                <b>No matching data</b>
                <Box variant="p" color="inherit">
                  There is no matching data to display
                </Box>
                <Button>Clear filter</Button>
              </Box>
            }
          />
        <Table
            columnDefinitions={tableColumnNames}
            items={tableRows}
            loadingText="Loading Jira SLA data"
            resizableColumns
            empty={
              <Box textAlign="center" color="inherit">
                <b>No Data</b>
                <Box
                  padding={{ bottom: "s" }}
                  variant="p"
                  color="inherit"
                >
                  No Jira SLA data to display.
                </Box>
              </Box>
            }
            header={
              <Header>
                <SpaceBetween direction="horizontal" size="xl">
                  Average Jira SLA Data Table
                  <Button variant="icon" iconName="download" onClick={() => {
                    let csvData = tableColumnNames.map(e => e.header) + "\n";
                    csvData += tableRows.map(e => e.name + "," + e.csv_values.join(",")).join("\n");
                    let csvContent = "data:text/csv;charset=utf-8," + csvData;
                    var encodedUri = encodeURI(csvContent);
                    var link = document.createElement("a");
                    link.setAttribute("href", encodedUri);
                    link.setAttribute("download", "coverity_avg_jira_sla.csv");
                    document.body.appendChild(link); 
                    link.click();
                  }}/> 
                </SpaceBetween> 
              </Header>
            }
          >
          </Table>
        </SpaceBetween>
      );
    }
    else { //Current live metrics
      const cardDefinition = {
          header: item => (
            <UserCardHeader user={item.user}/>
          ),
          sections: [
            {
              id: "current_sla_metric",
              header: "Average Jira SLA",
              content: item => (
                item.currentSLA.toFixed(0) > 0 ? item.currentSLA.toFixed(0) + " Days"  : '--'
              )
            },
            {
              id: "current_sla_trend",
              header: "Trend",
              content: item => (
                <TrendIndicator trend={item.trend} desiredTrend={'down'} pctChange={item.pctChange} lastQuarter={item.lastQuarter} lastValue={item.lastValue}/>
              )
            }
          ]
        };

      let seriesData = []
      for(const user of props.metricData) {
        if(user.current_metric_values && user.current_metric_values[props.metricName]) {
            if(user.historical_metric_values) {
              user.historical_metric_values.sort(quarterCompare);
            }
            
            let lastQuarter = user.historical_metric_values && user.historical_metric_values.length > 0 ? user.historical_metric_values[user.historical_metric_values.length-1] : null;
            let data = {
              user: user,
              currentSLA: user.current_metric_values[props.metricName].avg_sla,
              pctChange: 0,
              trend: 'none'
            };

            if(lastQuarter) {
              data['lastQuarter'] = lastQuarter.id;
              data['lastValue'] = lastQuarter.oss.avg_sla === 1 ? lastQuarter.oss.avg_sla.toFixed(0) + " Day" : lastQuarter.oss.avg_sla.toFixed(0) + " Days";
              
              if(lastQuarter[props.metricName].avg_sla < 1) {
                data['pctChange'] = user.current_metric_values[props.metricName].avg_sla * 100;
              }
              else {
                data['pctChange'] = Math.abs((user.current_metric_values[props.metricName].avg_sla - lastQuarter[props.metricName].avg_sla) / lastQuarter[props.metricName].avg_sla * 100);
              }
    
              if(user.current_metric_values[props.metricName].avg_sla > lastQuarter[props.metricName].avg_sla) {
                data['trend'] = 'up';
              }
              else if(user.current_metric_values[props.metricName].avg_sla < lastQuarter[props.metricName].avg_sla) {
                data['trend'] = 'down';
              }
              else {
                data['trend'] = 'same';
              }
            }
            seriesData.push(data);
        }
      }

      return (
        <div>
          <h3>Average Jira SLA</h3>
          <Cards
            cardDefinition={cardDefinition}
            items={seriesData ? seriesData : []}
            empty={
              <div className="awsui-util-t-c">
                <div className="awsui-util-pt-s awsui-util-mb-xs">
                  <b>No Data</b>
                </div>
                <p className="awsui-util-mb-s">
                  No Jira SLA data available
                </p>
              </div>	
            }
            loading={props.loadingState === 'loading' ? true: false}
            loadingText={'Loading metric'}
          />	
        </div>
      );
    }
  }

export default AverageSLAGraph;
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { SideNavigation, Toggle, SpaceBetween, Spinner, Button } from '@amzn/awsui-components-react';
import { setDarkMode, clearUserView, setLoggedInUserDetails } from '../../app/actions';
import { getApiURL } from "./envVarsReader";
import { getJwtToken } from './Auth';
import UserSearch from '../User/UserSearch';
import UserHierarchy from '../User/UserHierarchy';
import ClipboardCopy from './ClipboardCopy';
import PermissionsBadge from './PermissionsBadge';
import "../../styles/header.scss"

function DarkModeToggle(props) {
  return(
    <Toggle
      onChange={({ detail }) => {
        props.onChange(detail.checked);
      }}
      checked={props.state}
    >
      Dark Mode
    </Toggle>
  );
}

function SelfProfile(props) {
  let name = props.user;
  if(props.userDetails) {
    if(props.userDetails.person_details) {
      name = props.userDetails.person_details.name.includes(' ') ? props.userDetails.person_details.name.split(' ')[0] : props.userDetails.person_details.name;
    }
  }
  else {
    if(props.name) {
      name = props.name.includes(' ') ? props.name.split(' ')[0] : props.name;
    }
  }

  return(
    <SpaceBetween direction="vertical" size="xs">
      <SpaceBetween direction="horizontal" size="xs">
          <div><img className="circular-portrait small" src={"https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=" + props.user} alt='Phonetool'/></div>
          <div style={{'padding': '10px 0'}}>
              Hello, <b>{name}</b>!
          </div>
      </SpaceBetween>
      <PermissionsBadge permissions={props.userDetails.permissions ? props.userDetails.permissions : ''}/>
    </SpaceBetween>
  );
}

function CurrentlyDisplayedUser(props) {
  let baseURL = window.location.origin + '?';
  if(props.currentUsers && props.currentUsers.length > 0) {
    let tmp = [];
    for(const user of props.currentUsers) {
      tmp.push(user.id);
    }
    baseURL += 'userView=' + tmp.join(',');
  }
  if(props.metricRange) {
    if(baseURL.charAt(baseURL.length - 1) !== '?') {
      baseURL += '&';
    }
    baseURL += 'metricRange=' + props.metricRange.value;
  }
  if(props.metricView) {
    if(baseURL.charAt(baseURL.length - 1) !== '?') {
      baseURL += '&';
    }
    baseURL += 'metricView=' + props.metricView;
  }
  return(
    <SpaceBetween direction="vertical" size="xs">
      Currently Displayed Users
      <SpaceBetween direction="horizontal" size="xxs">
        <ClipboardCopy disabled={false} copyText={baseURL} successMessage={'URL with shareable view copied to clipboard.'}/>
        <Button onClick={() => {
          props.onUserViewCleared();
        }} variant="normal">Clear</Button>
      </SpaceBetween>
    </SpaceBetween>
  );
}

const header = { 
  text: 'STAR Dashboard',
  logo: { alt: "logo", src: process.env.PUBLIC_URL + "/logo.png", width: 20 }
};

export function Navigation (props) {
  const dispatch = useDispatch()

  const loggedInUser = useSelector((state) => state.currentViews.loggedInUser);
  const loggedInUserDetails = useSelector((state) => state.currentViews.loggedInUserDetails);
  const [loading, setLoading] = React.useState(true);
  const [commonUserViewItems, setCommonUserViewItems] = React.useState([]);
  const metricRange = useSelector((state) => state.currentViews.metricRange);
  const activeMetricsTab = useSelector((state) => state.currentViews.activeMetricsTab);
  const currentUsers = useSelector((state) => state.currentViews.currentUsers);
  const darkMode = useSelector((state) => state.currentViews.darkMode);
  const onDarkModeToggleChanged = (state) => {
    dispatch(setDarkMode(state));
  };
  const onUserViewCleared = () => {
    dispatch(clearUserView());
  };

  const loadLoggedInUserData = async(user_id) => {
    const options = {
        headers: {
            'Authorization': `Bearer ${getJwtToken()}`,
            'Content-Type': 'application/json'
        },
    }

    let commonUserViewItems = [
      {
        type: "link",
        text: "My Metrics",
        href: "?userView=" + loggedInUser
      }
    ];

    try {
        let response = await axios.get(`${getApiURL()}/user?id=` + user_id, options);
        let userData = response['data'];
        dispatch(setLoggedInUserDetails(userData));

        if(userData.person_details && userData.person_details.supervisor_chain && userData.person_details.supervisor_chain.length > 0) {
          commonUserViewItems.push({ //Metrics for individual's direct manager
            type: "link",
            text: userData.person_details.supervisor_chain[0].name + "'s Metrics",
            href: "?userView=" + userData.person_details.supervisor_chain[0].id
          });
      
          for(const supervisor of userData.person_details.supervisor_chain) {
            if(supervisor.title.includes("Director") && supervisor.id !== userData.person_details.supervisor_chain[0].id) {
              commonUserViewItems.push({ //Metrics for individual's nearest director
                type: "link",
                text: supervisor.name + "'s Metrics",
                href: "?userView=" + supervisor.id
              });
              break;
            }
          }
        }

    } catch (e) {
        console.log(e);    
    }

    setCommonUserViewItems(commonUserViewItems);
    setLoading(false);
  }

  useEffect(() => {
    if(loggedInUser) {
      setLoading(true);
      loadLoggedInUserData(loggedInUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser]);

  const items = [
    {
      type: 'link',
      info: <SelfProfile user={loggedInUser ? loggedInUser : 'User'} userDetails={loggedInUserDetails}/>
    },
    {
      type: 'divider'
    },
    {
      type: 'link',
      text: 'Search for Users',
      info: <UserSearch />
    },
    {
      type: 'expandable-link-group',
      text: 'Suggested Views',
      items: commonUserViewItems
    },
    {
      type: 'link',
      info: <DarkModeToggle state={darkMode} onChange={onDarkModeToggleChanged}/>
    },
    {
      type: 'divider'
    },
    {
      type: 'link',
      text: <CurrentlyDisplayedUser metricView={activeMetricsTab} metricRange={metricRange} currentUsers={currentUsers} onUserViewCleared={onUserViewCleared} />,
      info: <UserHierarchy/>
    }
  ];

  if(loading) {
    return(
    <SideNavigation
        items={[
          {
            type: 'link',
            info: <div align="center" style={{'marginTop': '250px'}}><Spinner size="large" /></div>
          }
        ]}
        header={header}
        activeHref={props.activeHref}
    />
    );
  }

  return (
    <SideNavigation
        items={items}
        header={header}
        activeHref={props.activeHref}
    />
  );
}
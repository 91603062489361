function quarterCompare(firstQuarter, secondQuarter) { 
    let firstQuarterData = firstQuarter.id.split(" ");
    let secondQuarterData = secondQuarter.id.split(" ");
    if(firstQuarterData[1] < secondQuarterData[1]) {
        return -1;
    }
    else if(firstQuarterData[1] > secondQuarterData[1]) {
        return 1;
    }

    if(firstQuarterData[0] < secondQuarterData[0]) {
        return -1;
    }
    else if(firstQuarterData[0] > secondQuarterData[0]) {
        return 1;
    }

    return 0;
}

module.exports = {
    Definitions: Object.freeze({
        QUARTERS: [
            {
                'name': 'Q1',
                'from_month': 1,
                'to_month': 3
            },
            {
                'name': 'Q2',
                'from_month': 4,
                'to_month': 6
            },
            {
                'name': 'Q2',
                'from_month': 7,
                'to_month': 9,
            },
            {
                'name': 'Q4',
                'from_month': 10,
                'to_month': 12,
            }
        ]
    }),
    quarterCompare: quarterCompare
}
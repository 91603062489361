import * as React from "react";
import { SpaceBetween } from '@amzn/awsui-components-react';
import '../../styles/user-card.scss';

export default function UserCard(props) {
    let badgeColor = "blue";
    if(props.user.person_details.tenure_days > 1825 && props.user.person_details.tenure_days < 3650) {
        badgeColor = "orange"
    }
    else if(props.user.person_details.tenure_days >= 3650 && props.user.person_details.tenure_days < 5475) {
        badgeColor = "red"
    }
    else if(props.user.person_details.tenure_days >= 5475 && props.user.person_details.tenure_days < 7300) {
        badgeColor = "purple"
    }
    else if(props.user.person_details.tenure_days >= 7300 && props.user.person_details.tenure_days < 9125) {
        badgeColor = "silver"
    }
    else if(props.user.person_details.tenure_days >= 9125) {
        badgeColor = "gold"
    }

    return(
        <SpaceBetween direction="horizontal" size="s">
            <div><img className={"circular-portrait small " + badgeColor} src={"https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=" + props.user.id} alt='Phonetool'/></div>
            <div>
                <h5 style={{'margin-top': '10px'}}>{props.user.person_details.name + "   (" + props.user.id + "@)"}</h5>
            </div>
        </SpaceBetween>
    );
}
import React from 'react';
import axios from "axios";
import { SpaceBetween, Link, Container, Button, ButtonDropdown } from '@amzn/awsui-components-react';
import { getApiURL } from "../../components/Common/envVarsReader";
import { getJwtToken } from '../../components/Common/Auth';
import '../../styles/user-card.scss';

function UserActions(props) {
    const [loading, setLoading] = React.useState(false);

    const fetchUserDetails = async(user_id) => {
        const options = {
            headers: {
                'Authorization': `Bearer ${getJwtToken()}`,
                'Content-Type': 'application/json'
            },
        }
        try {
            let response = await axios.get(`${getApiURL()}/user?id=` + user_id, options);
            return response['data'];
    
        } catch (e) {
            console.log(e);    
        }
        return null;
    }

    const replaceUser = async(new_user_ids) => {
        if(props.setCardsLoadingState) {
            props.setCardsLoadingState('loading');
        }
        
        for(const user_id of new_user_ids) {
            let userDetails = await fetchUserDetails(user_id);
            if(userDetails) {
                props.setSelectedUser(userDetails);
            }
        }
        props.removeSelectedUser(props.user);

        if(props.setCardsLoadingState) {
            props.setCardsLoadingState('finished');
        }
    }

    const addUser = async(user_id) => {
        setLoading(true);
        let userDetails = await fetchUserDetails(user_id);
        if(userDetails) {
            props.setSelectedUser(userDetails);
        }
        setLoading(false);
    }

    const grantAdminPermissions = async(user_id) => {
        const options = {
            headers: {
                'Authorization': `Bearer ${getJwtToken()}`,
                'Content-Type': 'application/json'
            },
        }
        try {
            let response = await axios.post(`${getApiURL()}/setUserPermissions?id=` + user_id + "&permissions=Admin", options);
            return response['data'];
    
        } catch (e) {
            console.log(e);    
        }
        return null;
    }

    let userCurrentlySelected = false;
    for(const user of props.currentUsers) {
        if(props.user.id === user.id) {
            userCurrentlySelected = true;
            break;
        }
    }

    //long format normal buttons
    if(props.format === 'long') {
        if(props.actionMode === 'remove') {
            return <Button onClick={() => props.removeSelectedUser(props.user)} variant="normal">Remove</Button>;
        }
        
        if(userCurrentlySelected) {
            return <Button disabled variant="normal">Select</Button>;
        }
        else if(loading) {
            return <Button loading variant="normal">Select</Button>;
        }
        return <Button onClick={() => addUser(props.user.id)} variant="normal">Select</Button>;
    }

    //short format icon buttons
    if(props.actionMode === 'remove') {
        let actions = [
            { id: "remove", text: "Remove User", iconName: 'close' }
        ]
        
        let hierarchyActions = [];
        if(props.user.person_details && props.user.person_details.supervisor_chain && props.user.person_details.supervisor_chain.length > 0) {
            hierarchyActions.push({ id: "view_supervisor", text: props.user.person_details.supervisor_chain[0].name, iconName: 'angle-up' });
        }
        if(props.user.person_details && props.user.person_details.direct_reports && props.user.person_details.direct_reports.length > 0) {
            let reportText = props.user.person_details.direct_reports.length === 1 ? " Direct Report" : " Direct Reports";
            hierarchyActions.push({ id: "view_reports", text: props.user.person_details.direct_reports.length + reportText, iconName: 'angle-down' });
        }
        if(hierarchyActions.length > 0) {
            actions.push({
                text: "View Data For...",
                items: hierarchyActions
              });
        }

        let adminActions = [];
        if(props.permissions === 'Admin') {
            if(!(props.user.permissions && props.user.permissions === 'Admin')) { //If user is not already an admin
                adminActions.push({ id: "grant_admin_permissions", text: 'Grant Admin Permissions', iconName: 'key' });
            }
        }
        if(adminActions.length > 0) {
            actions.push({
                text: "Administrative",
                items: adminActions
            })
        }

        return(
            <ButtonDropdown
                items={actions}
                ariaLabel="User actions"
                variant="icon"
                onItemClick={(item) => {
                    switch(item.detail.id) {
                        case 'remove':
                            props.removeSelectedUser(props.user);
                            break;
                        case 'view_supervisor':
                            replaceUser([props.user.person_details.supervisor_chain[0].id]);
                            break;
                        case 'view_reports':
                            replaceUser(props.user.person_details.direct_reports.map(direct_report => direct_report.id));
                            break;
                        case 'grant_admin_permissions':
                            grantAdminPermissions(props.user);
                            break;
                        default:
                            break;
                    }
                }}
            />
        );
    }

    if(userCurrentlySelected) {
        return <Button disabled iconName="add-plus" variant="icon" />;
    }
    else if(loading) {
        return <Button loading iconName="add-plus" variant="icon" />;
    }
    return <Button onClick={() => addUser(props.user.id)} iconName="add-plus" variant="icon" />;
 }

function UserTitle(props) {
    if(props.title.length > 20) {
        return(<p style={{'fontSize': '0.6em'}}>{props.title} &mdash; L{props.level}</p>)
    }
    return(<p style={{'fontSize': '0.8em'}}>{props.title} &mdash; L{props.level}</p>)
}

function UserName(props) {
    if(props.name.length > 14) {
        return(<b style={{'fontSize': '0.7em'}}>{props.name}</b>)
    }
    return(<b>{props.name}</b>)
}

export default function UserCard(props) {
    let badgeColor = "blue";
    if(props.user.person_details.tenure_days > 1825 && props.user.person_details.tenure_days < 3650) {
        badgeColor = "orange"
    }
    else if(props.user.person_details.tenure_days >= 3650 && props.user.person_details.tenure_days < 5475) {
        badgeColor = "red"
    }
    else if(props.user.person_details.tenure_days >= 5475 && props.user.person_details.tenure_days < 7300) {
        badgeColor = "purple"
    }
    else if(props.user.person_details.tenure_days >= 7300 && props.user.person_details.tenure_days < 9125) {
        badgeColor = "silver"
    }
    else if(props.user.person_details.tenure_days >= 9125) {
        badgeColor = "gold"
    }

    //long card format for search results
    if(props.format === 'long') {
        return(
            <Container
                footer={
                    <div align="right" style={{'marginTop': '-90px', 'marginRight': '10px'}}>
                        <UserActions 
                            user={props.user} 
                            format={props.format} 
                            actionMode={props.actionMode} 
                            setSelectedUser={props.setSelectedUser} 
                            removeSelectedUser={props.removeSelectedUser}
                            setCardsLoadingState={props.setCardsLoadingState} 
                            currentUsers={props.currentUsers}
                            permissions={props.permissions} 
                        />
                    </div>
                }
            >
                <SpaceBetween direction="horizontal" size="m">
                    <div><img className={"circular-portrait large " + badgeColor} src={"https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=" + props.user.id} alt='Phonetool'/></div>
                    <div>
                        <h3 style={{'marginTop': '4px'}}>{props.user.person_details.name + "   (" + props.user.id + "@)"}</h3>
                        <h4 style={{'marginTop': '-15px'}}>{props.user.person_details.title + " — L" + props.user.person_details.job_level}</h4>
                        <h5 style={{'marginTop': '-20px'}}><i>{props.user.person_details.cost_center_name}</i></h5>
                    </div>
                </SpaceBetween>
            </Container>
        );
    }

    //condensed card format for navbar
    return (
        <div>
            <div className="card-header" align="right">
                <UserActions 
                    user={props.user} 
                    format={props.format} 
                    actionMode={props.actionMode} 
                    setSelectedUser={props.setSelectedUser} 
                    removeSelectedUser={props.removeSelectedUser}
                    setCardsLoadingState={props.setCardsLoadingState} 
                    currentUsers={props.currentUsers}
                    permissions={props.permissions} 
                />
            </div>
            <Container>
                <SpaceBetween size="xxs">
                        <SpaceBetween direction="horizontal" size="xs">
                            <div><img className={"circular-portrait small " + badgeColor} src={"https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=" + props.user.id} alt='Phonetool'/></div>
                            <div>
                                <UserName name={props.user.person_details.name}/><br/>
                                <Link variant="primary" href={"https://phonetool.amazon.com/users/" + props.user.id}>{props.user.id}@</Link>
                            </div>
                        </SpaceBetween>
                        <hr className={'card-divider'}/>
                        <div align="center" style={{'marginTop':'-10px', 'marginBottom':'-5px'}}>
                            <UserTitle title={props.user.person_details.title} level={props.user.person_details.job_level}/>
                        </div>
                    </SpaceBetween>
            </Container>
        </div>
    );
}
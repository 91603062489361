import React from 'react';
import { connect } from 'react-redux'
import { Spinner, Alert, SpaceBetween } from '@amzn/awsui-components-react';
import UserCard from "./UserCard";

class UserCards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
	        loadingState: 'finished'
        };
        this.setLoadingState = this.setLoadingState.bind(this);
    }

    setLoadingState(state) {
        this.setState({loadingState: state});
    }
  
    render () {
        if(this.state.loadingState === 'loading' || this.props.state === 'loading') {
            return(<div align="center" style={{'marginTop': '50px'}}><Spinner size="large" /></div>);
        }
        else if(this.props.state === 'error') {
            return (
                <Alert
                    visible={true}
                    type="error"
                    header="Could not retrieve user details"
                >
                    Please enter a different user query or try again later.
                </Alert>
            ); 
        }
        
        const userCardList = this.props.users.map((user) => 
            <UserCard 
                key={user.id} 
                user={user} 
                format={this.props.format} 
                actionMode={this.props.actionMode} 
                setSelectedUser={this.props.setSelectedUser} 
                removeSelectedUser={this.props.removeSelectedUser}
                setCardsLoadingState={this.setLoadingState}  
                currentUsers={this.props.currentUsers}
                permissions={this.props.permissions}
            />
        );
        
        if(this.props.users.length < 1) {
            return (
                <div>
                    <br/>
                    <Alert
                        visible={true}
                        type="info"
                        header={this.props.emptyHeader}
                    >
                        {this.props.emptyMessage ? this.props.emptyMessage : ''}
                    </Alert>
                </div>
            );
        }

        return(
            <SpaceBetween direction="vertical" size="xxs">
                {userCardList}
            </SpaceBetween>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUsers: state.currentViews.currentUsers
    };
};

export default connect(mapStateToProps, null)(UserCards)